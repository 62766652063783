import React from "react"
import { Helmet } from "react-helmet"

const Head = () => {
  const title = 'Architecture Doing Place'
  const metaDescription = 'Architecture Doing Place'

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title="Architecture Doing Place"
      titleTemplate={`%s | ${title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: title,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      <link rel="preload" href="/fonts/font.woff2" as="font" crossOrigin="anonymus" />
      <link rel="preload" href="/fonts/font.woff" as="font" crossOrigin="anonymus" />
    </Helmet>
  )
}

export default Head
